import React from 'react';
import { ContactForm } from '../../components/ContactForm';

const Contacte = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

export { Contacte };